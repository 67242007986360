import { Routes, Route, Outlet } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Aktuelles } from "./views/Aktuelles";
import { Werwirsind } from "./views/Werwirsind";
import { Kalender } from "./views/Kalender";
import { Quartierszentrum } from "./views/Quartierszentrum";
import { Presse } from "./views/Presse";
import { Kontakt } from "./views/Kontakt";
import { Projekte } from "./views/Projekte";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { WebsiteStatistik } from "./views/WebsiteStatistik";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Aktuelles />} />
        <Route path="/werwirsind" element={<Werwirsind />} />
        <Route path="/kalender" element={<Kalender />} />
        <Route path="/projekte" element={<Projekte />} />
        <Route path="/quartierszentrum" element={<Quartierszentrum />} />
        <Route path="/presse" element={<Presse />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/website-statistik" element={<WebsiteStatistik />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <>
      <Navbar />
      <main className="container my-4">
        <div className="col">
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  );
}
