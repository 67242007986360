import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CollapsibleCard } from "../components/CollapsibleCard";
import { FloatLeftImage } from "../components/Image";

export function Presse() {
  return (
    <>
    <br></br>
      <h1 id="Presseberichte">Presseberichte</h1>
      <br></br>

      <ul>
        <li>
          <i>Augsburger Allgemeine - 10.07.2024 - Fridtjof Atterdahl</i>
              <br></br>
              <CollapsibleCard
                id="InLeichterSprache-1"
                title={"Der Verein „Qualle“ reagiert auf eine AfD-Anfrage"}
                content={
                  <>
                    <div className="d-flex flex-column">
                      <p>
                        Zum Online-Bericht (08.07.2024):{" "}
                        <a
                          href="https://www.augsburger-allgemeine.de/augsburg/der-verein-qualle-reagiert-auf-eine-afd-anfrage-102850583"
                          rel="noreferrer"
                          target="_blank"
                        >
                          "Der Verein „Qualle“ reagiert auf eine AfD-Anfrage"
                        </a>
                      </p>
                      <br></br>

                      <FloatLeftImage
                        src="img/AfD Bericht.png"
                        alt="10.07.24"
                        maxWidth="950px"
                      />
                    </div>
                    <i>
                      (Quelle: Print-Ausgabe der Augsburger Allgemeine vom
                      10.07.2024)
                    </i>
                  </>
                }
              />
            </li>

     <li>
        <i>Neue Szene - Onlinebericht 28. Juni 2024</i>
        <CollapsibleCard
        id="InLeichterSprache1"
        title={"Im Clinch mit der AfD-Stadtratsfraktion Augsburg"}
        content={
         <>
          <a
          href="https://www.neue-szene.de/magazin/news/im-clinch-mit-der-afd-stadtratsfraktion-augsburg"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          https://www.neue-szene.de/magazin/news/im-clinch-mit-der-afd-stadtratsfraktion-augsburg
        </a><br></br>
        <br></br>
         <i>(Quelle: Onlinebericht der Neuen Szene, 28.06.2024)</i>
         </>
        }
       />
      </li>

      <li>
        <i>Augsburger Allgemeine - 22.05.2024 - Fridtjof Atterdahl</i><br></br>
       <CollapsibleCard
        id="InLeichterSprache-1"
        title={"Projekt Qualle: Mitmachen statt demonstrieren"}
        content={
         <>
          <div className="d-flex flex-column">         <FloatLeftImage src="img/AZ Bericht (22.05.24).png"  maxWidth="950px" />
          </div>
         <i>(Quelle: Print-Ausgabe der Augsburger Allgemeine vom 22.05.2024)</i>
         </>
        }
        />
       </li>

        <li>
        <i>Augsburger Allgemeine - 28.10.2023 - Ina Marks</i><br></br>
       <CollapsibleCard
        id="InLeichterSprache0"
        title={"Seniorin geht gegen die Kippenflut vor"}
        content={
         <>
          <div className="d-flex flex-column">
            <p>Zum Online-Bericht: <a href="https://www.augsburger-allgemeine.de/augsburg/augsburg-eine-81-jaehrige-geht-gegen-die-kippenflut-in-der-wertachstrasse-vor-id68282816.html" rel="noreferrer" target="_blank">
           "Eine 81-Jährige geht gegen die Kippenflut in der Wertachstraße vor"</a>
            </p><br></br>
         <FloatLeftImage src="img/az artikel website.png"  maxWidth="950px" />
         </div>
         <i>(Quelle: Print-Ausgabe der Augsburger Allgemeine vom 28.07.2023)</i>
         </>
        }
       />
        </li>

        <li>
        <i>Neue Szene - Onlinebericht 13. Oktober 2023</i>
        <CollapsibleCard
        id="InLeichterSprache1"
        title={"Selbstverwaltetes Straßenfest in Rechts der Wertach"}
        content={
         <>
          <a
          href="https://www.neue-szene.de/magazin/news/selbstverwaltetes-stra%C3%9Fenfest-rechts-der-wertach"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          www.neue-szene.de/magazin/news/selbstverwaltetes-stra%C3%9Fenfest-rechts-der-wertach
        </a><br></br>
        <br></br>
         <i>(Quelle: Onlinebericht der Neuen Szene, 13.10.2023)</i>
         </>
        }
       />
        </li>

        <li>
        <i>Neue Szene - Printausgabe September 2023</i><br></br>
       <CollapsibleCard
        id="InLeichterSprache1"
        title={"Qualle - Quartier füer Alle"}
        content={
         <>
          <div className="d-flex flex-column">
         <FloatLeftImage src="img/PresseberichtNeueSzene.jpeg"  maxWidth="950px" />
         </div>
         <i>(Quelle: Print-Ausgabe der Neuen Szene, 9/2023)</i>
         </>
        }
       />
        </li>

        <li>
        <i>Augsburger Allgemeine - 23.07.2023 - Olivia Mannes</i><br></br>
        {/* <!-- In leichter Sprache --> */}
       <CollapsibleCard
        id="InLeichterSprache2"
        title={"Lobenswertes Engagement oder Gerüempel?"}
        content={
         <>
          <div className="d-flex flex-column">
         <FloatLeftImage src="img/AZ Bericht 1.png"  maxWidth="950px" />
         </div>
         <i>(Quelle: Print-Ausgabe der Augsburger Allgemeine vom 23.07.2023)</i>
         </>
        }
       />
        </li>

      </ul>

      <br></br>
      <h1 id="Pressemitteilungen">Pressemitteilungen</h1>
      <br></br>

      <ul>
        <li>
        <i>Pressestatement zur AfD-Anfrage</i><br></br>
          <CollapsibleCard
            id="InleichterSprache-2"
            title={"Statement zur AfD-Anfrage"}
            content={
            <>
              <div className="d-flex flex-column">
              <FloatLeftImage src="img/PM Qualle (28.06.).png"  maxWidth="950px" />
            </div>
            </>
            }
          />
        </li>

        <li>
        <i>Mittelstraßenfest 2024</i><br></br>
          <CollapsibleCard
            id="InleichterSprache-2"
            title={"Sommerfest in Rechts-der-Wertach: Ein buntes Wochenende für das Quartier"}
            content={
            <>
              <div className="d-flex flex-column">
              <FloatLeftImage src="img/mittelstraßenfest-pm-14-06-0.png"  maxWidth="950px" />
            <br></br>
            <FloatLeftImage src="img/mittelstraßenfest-pm-14-06-1.png"  maxWidth="950px" />
            </div>
            </>
            }
          />
        </li>


        <li>
        <i>Quallenfest - 13.Oktober 2023</i><br></br>
       <CollapsibleCard
        id="InleichterSprache-2"
        title={"Selbstorganisiertes Strassenfest in Rechts-der-Wertach"}
        content={
         <>
          <div className="d-flex flex-column">
         <FloatLeftImage src="img/Straßenfest PM (13.10.).png"  maxWidth="950px" />
         </div>
         </>
        }
       />
        </li>

        <li>
        <i>Kampagne Kippenfreie Wertachstraße - 13.Oktober 2023</i><br></br>
       <CollapsibleCard
        id="InleichterSprache-1"
        title={"Gemeinsam gegen die Flut an Kippenstummeln"}
        content={
         <>
          <div className="d-flex flex-column">
         <FloatLeftImage src="img/PM (13.10.).png"  maxWidth="950px" />
         </div>
         </>
        }
       />
        </li>
       <br></br>
      </ul>

      <br></br>
      <br></br>
    </>
  );
}

// In der Datei "./views/Idee.tsx"
export const Idee = () => {
  // Hier befindet sich die Implementierung der Idee-Komponente
};

