import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CollapsibleCard } from "../components/CollapsibleCard";
import { FloatLeftImage } from "../components/Image";

export function Werwirsind() {
  return (
    <>
      <br></br>
      <h1 id="idee">Idee</h1>
      <br></br>
      <p>
      Wir sind ein selbstorganisiertes Quartiersprojekt in Rechts-der-Wertach und haben uns zusammengefunden, um den komplexen Vielfachkrisen unserer Zeit auf lokaler Ebene entgegenzutreten. Dabei schaffen wir neue Räume des Miteinanders. Hier treffen Menschen ohne Konsumzwang aufeinander, um sich zu vernetzen, Sorgetätigkeiten gemeinsam zu organisieren, miteinander den Stadtteil durch eine Vielzahl lokaler Projekte (Demokratiecafé, Ort der Begegnung, offene Nachbarschaftsküche, Tauschschrank und Lebensmittelverteiler, Biodiversitäts- und Pflanzprojekte in den Straßen des Stadtteils, Organisation eines Straßenfestes und vieles mehr) zu gestalten, demokratische Bildungsarbeit zu organisieren und auf diesem Wege Demokratie als solidarische Lebensform erfahrbar zu machen.<br></br>
      <br></br>
      Den zunehmenden gesellschaftlichen Spannungen und Spaltungen wollen wir mit konkreter nachbarschaftlicher Solidarität begegnen. Eine widerstandsfähige Zivilgesellschaft wird durch demokratisches und integratives Engagement vor Ort gestärkt. Wir sind der Ansicht, dass Veränderungen im lokalen Zusammenleben sich auch auf größere Strukturen auswirken - <i>Think global, act local</i>.
      <br></br>
      <br></br>
      </p>
      <hr />

      <h1 id="überuns">Ueber uns</h1>
      <br></br>
      <p>
      Nachdem die Qualle im Juni 2023 ins Leben gerufen wurde, hat sich schnell eine Gruppe von Menschen mit unterschiedlichsten Backgrounds zusammengefunden, um konstruktive Veränderungen im Quartier Rechts-der-Wertach anzustoßen.
      Dabei engagieren sich Menschen verschiedener Altersklassen und soziokultureller Kontexte in ihrer freien Zeit. Bei unserer Arbeit orientieren wir uns an den Prinzipien <i>Gewaltfreiheit</i> und <i>Basisdemokratie</i> und versuchen uns möglichst ohne Hierarchien zu organisieren, um eine solidarische Lebensweise in der Praxis einzuüben.<br></br>
      <br></br>
      </p>
      <hr />


      <h1 id="verein">Verein "Qualle - Quartier fuer Alle e.V."</h1>
      <br></br>
      <div>
        <p>
          Der Verein wurde im November 2023 gegründet. Ziel ist es, die administrativen Aufgaben des Quartiersprojekts zu übernehmen, sodass sich engagierte Ehrenamtliche und Menschen aus Rechts-der-Wertach auf die Planung und Umsetzung konkreter Projekte konzentrieren können.<br></br>
          Wie du uns unterstützen kannst, erfährst du <a
          href="https://www.qualle-augsburg.de/kontakt"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          hier
        </a>.
          <br></br>
          <br></br>
          <br></br>
          <b>Unterstützende Initiativen & Organisationen</b><br></br>
          <br></br>
          <div className="d-flex flex-column">
          <img
          style={{
            maxWidth: "1000px",
            width: "100%",
            marginBottom: "1em",
            marginTop: "-1em",
          }}
          src="img/Unterstützi.png"
          alt="Oase"
          className="float-left"
          />
          </div>
          <br></br>

          <b>Kuratorium</b>
          <br></br>
          <br></br>
          Das Kuratorium besteht aus Personen, die wichtige Beiträge zur lokalen Quartiersentwicklung in Rechts-der-Wertach wie auch Stadtteil-übergreifend leisten. Ihre Aufgabe innerhalb der Vereinsstruktur ist es, der Arbeit des Quartiersprojets beratend zur Seite zu stehen und die Umsetzung konkreter Projekten mit know-how und Erfahrung zu bereichern.<br></br>
          <br></br>
          <br></br>

          <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-1">
              <img
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "0.5em",
                }}
                src="img/Jan.png"
                alt="Oase"
                className="float-left"
              />
            </div>
            <div className="col-lg-6 order-lg-2" style={{ textAlign: 'justify' }}>
              <i>"Als Quartiersmanager ist es mir ein Freude zu sehen, wie sich in kurzer Zeit aus einer Vision junger Menschen ein gemeinnütziger Verein von Bürger*innen aller Altersgruppen entwickelt hat. Gerne unterstütze ich den Verein mit meiner Erfahrung aus Prozessen aktivierender und gemeinwohlorientierter Stadtentwicklung!"</i>
            </div>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-6 order-lg-2" style={{ textAlign: 'justify' }}>
              <img
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "0.5em",
                  float: 'right',
                }}
                src="img/Tine.png"
                alt="Oase"
              />
            </div>
            <div className="col-lg-6 order-lg-1" style={{ textAlign: 'justify' }}>
              <i>"Mein Lieblingsmotto »Wuiseln gilt net« ist gerade durch Engagement im Stadtteil gut umsetzbar. Man kann natürlich schon mal maulen und jammern – aber dann muss man auch was tun gegen das, was die Maulerei und die Jammerei auslöst. Im Stadtteil kann ich oft sehr schnell Verbesserungen bewirken: unmittelbar, in Gemeinschaft mit anderen, in direkter Kommunikation und Aktion, dort wo es zwickt und zwackt. Ein gutes Leben für alle - das möchten wir doch für jede und jeden."</i>
            </div>
          </div>
          <br></br>
          <br></br>

          <div className="row">
            <div className="col-lg-6 order-lg-1">
              <img
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "0.5em",
                }}
                src="img/Vincent.png"
                alt="Oase"
                className="float-left"
              />
            </div>
            <div className="col-lg-6 order-lg-2" style={{ textAlign: 'justify' }}>
              <i>"Als Nachbar in Rechts-der-Wertach und Vorstandsmitglied des Stadtjugendrings Augsburg freut es mich ungemein den Verein dabei zu unterstützen, die Vision eines solidarischen Viertels für jeden zu schaffen - unabhängig von Alter, Geschlecht oder sozialem Status. Eine lebenswerte Zukunft kann und wird nur gemeinsam gestaltet werden können."</i>
            </div>
          </div>
        </div>


        </p>
        <br></br>
        <CollapsibleCard
        id="InLeichterSprache0"
        title={"Antrag zur Mitgliedschaft"}
        content={
         <>
          <div className="d-flex flex-column">
            <p>Werde Mitglied im Verein <i>Qualle - Quartier für Alle e.V.</i><br></br>
            Lade das Dokument runter und schicke es ausgefüllt an info@qualleaugsburg.de
            </p><br></br>
         <FloatLeftImage src="img/Beitrittsformular Qualle 2024.png"  maxWidth="950px" />
         </div>
         <i>*Der Jahresmitgliedsbeitrag für eine reguläre Mitgliedschaft wurde in der Mitgliederversammlung am 17.12.2023 auf 12€ festgelegt.</i>
         </>

        }
       />

        <CollapsibleCard
        id="InLeichterSprache1"
        title={"Vereinssatzung (Stand: 15.11.2023)"}
        content={
         <>
         <div className="d-flex flex-column">
         <FloatLeftImage src="img/S1.png"  maxWidth="950px" />
         <FloatLeftImage src="img/S2.png"  maxWidth="950px" />
         <FloatLeftImage src="img/S3.png"  maxWidth="950px" />
         <FloatLeftImage src="img/S4.png"  maxWidth="950px" />
         </div>
         </>
        }
       />

        <CollapsibleCard
        id="InLeichterSprache2"
        title={"Vereinssatzung (in leichter Sprache)"}
        content={
         <>
         <div className="d-flex flex-column">
         <FloatLeftImage src="img/Sls1.png"  maxWidth="950px" />
         <FloatLeftImage src="img/verein/Sls2.png"  maxWidth="950px" />
         <FloatLeftImage src="img/Sls3.png"  maxWidth="950px" />
         </div>
         </>
        }
       />

        <CollapsibleCard
        id="InLeichterSprache3"
        title={"Vereinssatzung (Englisch)"}
        content={
         <>
         <div className="d-flex flex-column">
         <FloatLeftImage src="img/Se1.png"  maxWidth="950px" />
         <FloatLeftImage src="img/Se2.png"  maxWidth="950px" />
         <FloatLeftImage src="img/Se3.png"  maxWidth="950px" />
         <FloatLeftImage src="img/Se4.png"  maxWidth="950px" />
         </div>
         </>
        }
       />

      <br></br>
      <br></br>
      <b>Ziele des Vereins</b>
      <br></br>
       <i>(Auszug aus §2 der Satzung)</i><br></br>
       <p>
       Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke [...].<br></br>
       Zweck des Vereins ist die Förderung des bürgerschaftlichen Engagements und das Schaffen und Stärken lokaler demokratischer Strukturen. Darüber hinaus setzt er sich für die Förderung von Kunst und Kultur sowie von Umweltschutz ein. Dabei stehen stets die in der Präambel formulierten Haltungen und Werte im Zentrum. Der Satzungszweck wird insbesondere umgesetzt durch<br></br>
       a. Schaffen von Begegnungsorten zur Stärkung der Gemeinschaftlichkeit im Quartier Rechts der Wertach und zum Austausch über demokratische Werte sowie zur Vernetzung der Nachbarschaft beispielsweise durch Errichtung eines Quartierszentrums.<br></br>
       b. Förderung von Kunst und Kultur durch Veranstaltungen, die Menschen mit unterschiedlichen soziokulturellen Hintergründen zusammenbringen, wie etwa Konzerten und Straßenfesten.<br></br>
       c. Verwirklichung von Projekten zum Umweltschutz und zur Förderung der Lebensqualität im Quartier etwa durch Biodiversitätsprojekte, Müllsammelaktionen und Errichtung von Mülleimern für Zigarettenstummel sowie dem Aufbau und Unterhalt einer Foodsharing- Station und eines Tauschschrankes.<br></br>
       </p>

      </div>
      <hr />
      <br></br>
    </>
  );

}

