import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CollapsibleCard } from "../components/CollapsibleCard";
import { FloatLeftImage } from "../components/Image";

export function Quartierszentrum() {
  return (
    <>
      <br></br>
      <h1 id="QZ">Ort der Begegnung</h1>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/ODB.jpeg"  />
        </div>
        <p>
          Mit dem Quartierszentrum haben wir einen Ort der Begegnung geschaffen, in welchem sich Nachbar*innen treffen und vernetzen können. Erst wenn neue Räume entstehen, kann gestalterisches Potenzial der gemeinsamen Arbeit erkannt und in die Tat umgesetzt werden.<br></br>
          Außerdem ist das QZ Ausgangspunkt unserer organisatorischen Aktivitäten und Rückzugsort für die Aktiven der Qualle. Von hier aus planen wir Projekte, Kampagnen und Aktionen und veranstalten Kunst- und Kulturveranstaltungen. Somit wir das QZ zum lebendigen Ort in unserer direkten Nachbarschaft und zum Kristallisationspunkt der Veränderung im Quartier Rechts-der-Wertach.<br></br>
          <br></br>
          Schau im <a
          href="https://www.qualle-augsburg.de/kalender"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Kalender
        </a> nach, wann wir vor Ort sind und komm vorbei. Jede unserer Aktivitäten, jedes Treffen ist für alle offen.
        </p>
        <br></br>
        <hr />

        <h1 id="nutzediesenraum">Nutze diesen Raum!</h1>
        <br></br>
        <div className="image-gallery">
          <div className="image-container">
            <FloatLeftImage src="img/raum1.jpg" alt="raum1" />
          </div>
        </div>
        <br></br>
        <div className="image-gallery">
          <div className="image-container">
            <FloatLeftImage src="img/raum 2.jpg" alt="raum2" />
          </div>
          <div className="image-container">
            <FloatLeftImage src="img/raum3.JPG" alt="raum3" />
          </div>
        </div>

        <p>
        Das Quartierszentrum bietet Platz für dein Projekt oder deine Gruppe. Wir möchten unsere Räumlichkeiten auch anderen Vereinen, Organisationen oder losen Zusammenschlüssen zur Verfügung stellen. Wenn du Interesse an einer Raumnutzung hast, komm auf uns zu.<br></br>
        Wann das Quartierszentrum frei ist, erfährst du über den <a
          href="https://www.qualle-augsburg.de/kalender"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Kalender
        </a>.
        </p>
        <br></br>
        <hr />

        <h1 id="bibliothekderdinge">Bibliothek der Dinge</h1>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/Bibliothek der Dinge.png"  />
        </div>
        <p>
         Die Bibliothek der Dinge ist eröffnet!<br></br>
         <br></br>Hier kannst Du Gegenstände leihen, die man im täglichen und gelegentlichen Gebrauch nutzt. Beispielsweise eine Bohrmaschine, eine Kabeltrommel, eine Leiter, oder den passenden Schraubenzieher. Warum sollte jeder Haushalt einen eigenen Werkzeugkoffer besitzen?<br></br>
          Wenn Du eine Sache übrig hast und sie für die Menschen im Quartier zur Verfügung stellen möchtest, oder etwas in unserem Repertoire fehlt, schreib uns eine Mail.<br></br>

          <br></br>
          <CollapsibleCard
            id="InLeichterSprache1"
            title={"Ausleihe"}
            content={
            <>
              Gegen einen Pfand kannst du aktuell folgende Dinge im QZ ausleihen. Reserviere per Mail und schlage Termine vor, wenn du den Gegenstand im QZ abholen kannst: info@qualleaugsburg.de<br></br>
              <br></br>
              <i>Strom:</i> <br></br>
              <ul>
                <li>Kabeltrommel (20m)</li>
                <li>Mehrfachsteckdose (3er)</li>
                <li>Bildschirm</li>
              </ul>

              <i>Fahrrad:</i> <br></br>
              <ul>
                <li>Lastenrad</li>
                <li>Satteltasche</li>
                <li>Kettenöl (MTB)</li>
                <li>Fahrradanhänger</li>
                <li>Fahrradpumpe (keine Leihe)</li>
              </ul>

              <i>Bohren & Schrauben:</i> <br></br>
              <ul>
                <li>Bohrmaschine</li>
                <li>Hand-Bohrmaschine</li>
                <li>Bohrer-Set</li>
                <li>Akkuschrauber</li>
                <li>Bit-Set</li>
                <li>Bohrer-set</li>
              </ul>

              <i>Für den Alltag:</i> <br></br>
              <ul>
                <li>Rollwagen</li>
                <li>Elektrische Heizung</li>
                <li>Spanngurte</li>
                <li>Nähmaschine</li>
                <li>Drucker (keine Leihe)</li>
                <li>Leiter (klein: 1m Stehhöhe)</li>
                <li>Besen (Hartborsten)</li>
                <li>Vakuumiergerät</li>
                <li>Wäscheleine (20m)</li>
                <li>Klappstuhl (2x)</li>
                <li>Seil (8m/10m/16m)</li>
                <li>Musikbox (I Dance Groove 215)</li>
                <li>Zelt (2 Personen)</li>
                <li>Campingstuhl</li>
                <li>Tarp</li>
                <li>Rucksack (25L)</li>
                <li>Lederpflege</li>
                <li>Slakline</li>
              </ul>

              <i>Licht:</i> <br></br>
              <ul>
                <li>Baulampe</li>
                <li>Tischlampe</li>
                <li>Akkustrahler</li>
                <li>Stirnlampe</li>
              </ul>

              <i>Handwerkzeug:</i> <br></br>
              <ul>
                <li>Handsäge (Holz)</li>
                <li>Diverse Stämmeisen & Meißel (4x)</li>
                <li>Ratsche</li>
                <li>Kleine Schraubenziehze (electreo)</li>
                <li>Malenbedarf</li>
                <li>Gabelschlüssel</li>
                <li>Hobel</li>
                <li>Hammer</li>
                <li>Zange</li>
                <li>Schraubenzieher</li>
                <li>Wasserwage</li>
                <li>Cuttermesser</li>
                <li>Verstellbarer Schraubenschlüssel</li>
                <li>Schere</li>
                <li>Schlüssel</li>
                <li>Zirkel</li>
                <li>Zollstock</li>
                <li>Winkel</li>
                <li>Schraubzwinge (2)</li>
                <li>Baugurten</li>
                <li>3 Stufe Leiter</li>
              </ul>
              <i>Was wir noch suchen:</i> <br></br>
              <ul>
                <li>Schutzbrille</li>
                <li>Gehörschutz</li>
                <li>Handsage (Metal)</li>
                <li>Aufladegerät Batterien</li>
              </ul>
              {" "}
            </>
            }
          />





          <br></br>
        </p>

        <hr />

        <h1 id="kippenauffangstation">Kippenauffangstation</h1>
        <br></br>
        <div className="d-flex flex-column">
        <FloatLeftImage src="img/kippenstation.jpg"  />
        </div>
        <p>
          Im Rahmen der <a
          href="https://www.qualle-augsburg.de/projekte#kippenprojekt"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Kippenaktion
        </a> sammeln wir regelmäßig die Zigarettenstummel aus den von uns angebrachten Aschenbecher-Kästen auf der Wertachstraße. Zusätzlich können Menschen ihre Stummel selbstständig sammeln - dazu eignet sich hervorragend einer unser Sammelbehälter aus Glas - und bei uns abgeben. Wir sammeln alle Stummel in einer Luftdichten Tonne, welche einmal im Jahr von <a
          href="https://tobacircle.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          TobaCircle e.V.
        </a> abgeholt wird. Die Kippenstummel werden anschließend als Sondermüll entsorgt - statt sie als Restmüll zu verbrennen - und können sogar teilweise sogar recyclet werden. Melde dich, wenn du mitmachen möchtest.
        </p>
        <br></br>
        <hr />
    </>
  );
}
